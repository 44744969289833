import React from 'react';
import {
  Accordion as ChakraAccordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';

import { Section } from '@components/atoms/Section';
import { RichText } from "@components/atoms/RichText";

import { tableStyles } from './styles';

export const AccordionWithTable = ({ data }) => {
    return (
        <Section
            px={{ base: `1.563rem`, sml: `3.125rem !important` }}
            py="0 !important">
            <ChakraAccordion allowMultiple>
                <AccordionItem
                key="Alpha cup fit chart"
                borderTop="none"
                marginBottom="1.5rem"
                borderBottom="1px solid #ECECEC"
                >
                    <h2>
                        <AccordionButton
                        color="dsg.brand.50"
                        px="0px"
                        py="0"
                        mb="1rem"
                        _hover={{
                            background: `none`,
                        }}>
                            <Box
                                flex="1"
                                as="span"
                                textAlign="left"
                                fontFamily="brand"
                                fontSize="delta"
                                lineHeight="1.4"
                                textTransform="uppercase"
                            >
                                {data.alpha_cup_fit_heading}
                            </Box>
                            <AccordionIcon color="dsg.brand.50" fontSize="1.7em"/>
                        </AccordionButton>
                    </h2>
                    <AccordionPanel
                        fontSize="body"
                        fontWeight="500"
                        color="dsg.neutral.200"
                        p="1rem 0"
                    >
                        <RichText
                            isComponent={false}
                            markdown={data.alpha_cup_fit_text.data.alpha_cup_fit_text}
                            fontSize="body"
                            fontWeight="500"
                            display="block"
                            color="dsg.neutral.200"
                        />
                        <Box overflowX="auto">
                            <Table variant='simple' sx={tableStyles} mt="1rem" mb="1.375rem">
                                <Thead>
                                    <Tr>
                                        <Th scope="col" fontSize="0.625rem!important" minW="5.125rem">Band size inches</Th>
                                        <Th scope="col" minW="3rem">A</Th>
                                        <Th scope="col" minW="3rem">B</Th>
                                        <Th scope="col" minW="3rem">C</Th>
                                        <Th scope="col" minW="3rem">D</Th>
                                        <Th scope="col" minW="3rem">DD</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    <Tr>
                                        <Th scope="row">29</Th>
                                        <Td colSpan="3">2XS A-C</Td>
                                        <Td colSpan="2">2Xs D-DD</Td>
                                    </Tr>
                                    <Tr>
                                        <Th scope="row">30</Th>
                                        <Td colSpan="3" rowSpan="2">XS A-C</Td>
                                        <Td colSpan="2" rowSpan="2">XS D-DD</Td>
                                    </Tr>
                                    <Tr>
                                        <Th scope="row">32</Th>
                                    </Tr>
                                    <Tr>
                                        <Th scope="row">34</Th>
                                        <Td colSpan="3">S A-C</Td>
                                        <Td colSpan="2">S D-DD</Td>
                                    </Tr>
                                    <Tr>
                                        <Th scope="row">36</Th>
                                        <Td colSpan="3">M A-C</Td>
                                        <Td colSpan="2">M D-DD</Td>
                                    </Tr>
                                    <Tr>
                                        <Th scope="row">38</Th>
                                        <Td colSpan="3">L A-C</Td>
                                        <Td colSpan="2">L D-DD</Td>
                                    </Tr>
                                    <Tr>
                                        <Th scope="row">40</Th>
                                        <Td colSpan="3" rowSpan="2">XL A-C</Td>
                                        <Td colSpan="2" rowSpan="2">XL D-DD</Td>
                                    </Tr>
                                    <Tr>
                                        <Th scope="row">42</Th>
                                    </Tr>
                                    <Tr>
                                        <Th scope="row">44</Th>
                                        <Td colSpan="3">2XL A-C</Td>
                                        <Td colSpan="2">2XL D-DD</Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                        </Box>
                    </AccordionPanel>
                </AccordionItem>
                <AccordionItem
                key="Alpha fit chart"
                borderTop="none"
                marginBottom="1rem"
                borderBottom="1px solid #ECECEC">
                    <h2>
                        <AccordionButton
                            color="dsg.brand.50"
                            px="0px"
                            py="0"
                            mb="1rem"
                            _hover={{
                                background: `none`,
                            }}
                        >
                            <Box
                                flex="1"
                                as="span"
                                textAlign="left"
                                fontFamily="brand"
                                fontSize="delta"
                                lineHeight="1.4"
                                textTransform="uppercase"
                                >
                                {data.alpha_fit_heading}
                            </Box>
                            <AccordionIcon color="dsg.brand.50" fontSize="1.7em"/>
                        </AccordionButton>
                    </h2>
                    <AccordionPanel
                        fontSize="body"
                        fontWeight="500"
                        color="dsg.neutral.200"
                        p="1rem 0">
                        <RichText
                            isComponent={false}
                            markdown={data.alpha_fit_text.data.alpha_fit_text}
                            fontSize="body"
                            fontWeight="500"
                            display="block"
                            color="dsg.neutral.200"
                        />
                        <Box overflowX="auto">
                            <Table variant='simple' sx={tableStyles} mt="1rem" mb="1.375rem">
                                <Thead>
                                    <Tr>
                                    <Th scope="col" fontSize="0.625rem!important" minW="4.125rem">Bra Size</Th>
                                    <Th scope="col" minW="3.125rem">A</Th>
                                    <Th scope="col" minW="3.125rem">B</Th>
                                    <Th scope="col" minW="3.125rem">C</Th>
                                    <Th scope="col" minW="3.125rem">D</Th>
                                    <Th scope="col" minW="3.125rem">DD</Th>
                                    <Th scope="col" minW="3.125rem">E</Th>
                                    <Th scope="col" minW="3.125rem">F</Th>
                                    <Th scope="col" minW="3.125rem">G</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    <Tr>
                                        <Th scope="row">30</Th>
                                        <Td>2XS</Td>
                                        <Td>XS</Td>
                                        <Td>XS</Td>
                                        <Td>XS</Td>
                                        <Td>XS</Td>
                                        <Td>S</Td>
                                        <Td>S</Td>
                                        <Td>M</Td>
                                    </Tr>
                                    <Tr>
                                        <Th scope="row">32</Th>
                                        <Td>XS</Td>
                                        <Td>XS</Td>
                                        <Td>S</Td>
                                        <Td>S</Td>
                                        <Td>S</Td>
                                        <Td>M</Td>
                                        <Td>M</Td>
                                        <Td>M</Td>
                                    </Tr>
                                    <Tr>
                                        <Th scope="row">34</Th>
                                        <Td>S</Td>
                                        <Td>S</Td>
                                        <Td>S</Td>
                                        <Td>M</Td>
                                        <Td>M</Td>
                                        <Td>M</Td>
                                        <Td>L</Td>
                                        <Td>L</Td>
                                    </Tr>
                                    <Tr>
                                        <Th scope="row">36</Th>
                                        <Td>M</Td>
                                        <Td>M</Td>
                                        <Td>M</Td>
                                        <Td>L</Td>
                                        <Td>L</Td>
                                        <Td>L</Td>
                                        <Td>L</Td>
                                        <Td>XL</Td>
                                    </Tr>
                                    <Tr>
                                        <Th scope="row">38</Th>
                                        <Td>L</Td>
                                        <Td>L</Td>
                                        <Td>L</Td>
                                        <Td>XL</Td>
                                        <Td>XL</Td>
                                        <Td>XL</Td>
                                        <Td>XL</Td>
                                        <Td>XL</Td>
                                    </Tr>
                                    <Tr>
                                        <Th scope="row">40</Th>
                                        <Td>XL</Td>
                                        <Td>XL</Td>
                                        <Td>XL</Td>
                                        <Td>XL</Td>
                                        <Td>1X</Td>
                                        <Td>1X</Td>
                                        <Td>1X</Td>
                                        <Td>1X</Td>
                                    </Tr>
                                    <Tr>
                                        <Th scope="row">42</Th>
                                        <Td>1X</Td>
                                        <Td>1X</Td>
                                        <Td>1X</Td>
                                        <Td>1X</Td>
                                        <Td>2X</Td>
                                        <Td>2X</Td>
                                        <Td>2X</Td>
                                        <Td>2X</Td>
                                    </Tr>
                                    <Tr>
                                        <Th scope="row">44</Th>
                                        <Td>2X</Td>
                                        <Td>2X</Td>
                                        <Td>2X</Td>
                                        <Td>2X</Td>
                                        <Td>3X</Td>
                                        <Td>3X</Td>
                                        <Td>3X</Td>
                                        <Td>3X</Td>
                                    </Tr>
                                    <Tr>
                                        <Th scope="row">46</Th>
                                        <Td>3X</Td>
                                        <Td>3X</Td>
                                        <Td>3X</Td>
                                        <Td>3X</Td>
                                        <Td>4X</Td>
                                        <Td>4X</Td>
                                        <Td>4X</Td>
                                        <Td>4X</Td>
                                    </Tr>
                                    <Tr>
                                        <Th scope="row">48</Th>
                                        <Td>4X</Td>
                                        <Td>4X</Td>
                                        <Td>4X</Td>
                                        <Td>4X</Td>
                                        <Td>4X</Td>
                                        <Td>4X</Td>
                                        <Td>4X</Td>
                                        <Td>4X</Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                        </Box>
                    </AccordionPanel>
                </AccordionItem>
            </ChakraAccordion>
        </Section>
    )
};
