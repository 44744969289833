export const richTextStyles = {
  'h1, h2, h3, h4, h5, h6': {
    lineHeight: `1`,
    fontWeight: `700`,
    color: `betland.black`,
    marginBottom: `1rem`
  },

  h1: { fontSize: `alpha` },
  h2: { fontSize: [`beta`, `alpha`], paddingBottom: [`10px`, `20px`] },
  h3: { fontSize: `gamma`, paddingBottom: [`10px`, `20px`] },
  h4: { fontSize: `delta`, paddingBottom: [`10px`, `20px`] },
  h5: { fontSize: `delta`, paddingBottom: [`10px`, `10px`] },
  h6: { fontSize: `delta`, paddingBottom: [`10px`, `10px`] },

  p: {
    marginBottom: `1rem`
  },

  'li > p': {
    display: `inline`
  },

  li: {
    paddingBottom: `.5rem`,
    paddingLeft: `1rem`
  },

  'li::before': {
    content: `"•"`,
    color: `dsg.neutral.200`,
    display: `inline-block`,
    width: `1rem`, 
    marginLeft: `-1rem`,
  },

  'ul, ol': {
    listStyle: `none`
  },

  a: {
    fontWeight: `700`,
  },
  'table, th, td': {
    border: `1px solid`
  },
  'th, td': {
    padding: `0.5rem`
  },
  table: {
    margin: `2rem 0`
  }
};
