import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Image as ChakraImage } from '@chakra-ui/react';

import { Video } from '@components/atoms/Video';

const VIDEO_EXTENSIONS = [`.mp4`, `.mpeg`, `.wmv`, `.avi`];

export const Media = ({ media, aria, image, video, ...rest }) => {
  let isGatsbyImage = typeof media?.localFile?.childImageSharp?.gatsbyImageData !== 'undefined';
  const gatsbyImage = media?.localFile?.childImageSharp?.gatsbyImageData;


    const mediaExtension = video?.ext;

    if (VIDEO_EXTENSIONS.includes(mediaExtension) === true) {
      return <Video relativeURL={video.url} controls />;
    }

    if (mediaExtension === `.gif` || isGatsbyImage === false) {
      return (
        <ChakraImage
          htmlwidth="100%"
          src={`${process.env.URL}${image.url}`}
          {...rest}
        />
      );
    }

    if (isGatsbyImage === true) {

      return (
        <ChakraImage
          htmlwidth="100%"
          as={GatsbyImage}
          image={gatsbyImage}
          alt={media.alternativeText}
          {...rest}
        />
      );
    }

    return <ChakraImage src={image.src} alt={image.alt} />;

};
