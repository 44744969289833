import React from 'react';

import { SEO } from '@components/molecules/SEO';
import { CookieScript } from '@components/molecules/CookieScript';
import { Header } from '@components/molecules/Header';
import { Footer } from '@components/molecules/Footer';

export const Layout = ({ children, seo, location, headerData, footerData }) => (
  <>
  <div style={{maxWidth: '47.938rem', margin: '0 auto' }}>
    <SEO seo={seo} location={location} />
    <CookieScript />
    <Header data={headerData} location={location} />
    <main>{children}</main>
    <Footer data={footerData} />
    </div>
  </>
);
