import React from 'react';

import { RichText } from '@components/atoms/RichText';
import { Section } from '@components/atoms/Section';

export const RichTextSection = ({ data }) => {
  const markdown = data.body.data.body;
  return (
  <Section isNarrow>
    <RichText markdown={markdown} />
  </Section>
)};
