import React from 'react';

import { Layout } from '@components/Layout';

import { createComponent } from '@helpers/createComponent';

const Page = ({
  pageContext: { seo, page_meta, header, footer, body, allImages, ...rest },
  location
}) => {
  
  return (
  <Layout
    seo={{ ...seo, page_meta_title: page_meta.page_title }}
    location={location}
    headerData={header}
    footerData={footer}>
    {body.map((props, index) => createComponent({ ...props, ...rest, index }))}
  </Layout>
)};

export default Page;
