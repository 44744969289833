import React from 'react';
import {
  Accordion as ChakraAccordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box
} from '@chakra-ui/react';

import { Section } from '@components/atoms/Section';

export const Accordion = ({
  data: { items = [], skip_link }
}) => (
  <Section
    px={{ base: `1.563rem`, sml: `3.125rem !important` }}
    pt="2rem !important"
    pb="0! important"
    id={skip_link}
  >
    <ChakraAccordion allowMultiple>
      {items.map((item, idx) => (
        <AccordionItem
          key={`${item.__typename}-${idx}`}
          borderTop="none"
          marginTop="1rem"
          borderBottom="1px solid #ECECEC">
              <h2>
                <AccordionButton
                color="dsg.brand.50"
                px="0px"
                py="0"
                mb="1rem"
                _hover={{
                  background: `none`,
                }}>
                  <Box
                    flex="1"
                    as="span"
                    textAlign="left"
                    fontFamily="brand"
                    fontSize="delta"
                    lineHeight="1.4"
                    textTransform="uppercase"
                    >
                    {item.heading}
                  </Box>
                  <AccordionIcon color="dsg.brand.50" fontSize="1.7em"/>
                </AccordionButton>
              </h2>
              <AccordionPanel
                fontSize="body"
                fontWeight="500"
                color="dsg.neutral.200"
                p="1rem 0">
                {item.body}
              </AccordionPanel>
        </AccordionItem>
      ))}
    </ChakraAccordion>
  </Section>
);
