import React from 'react';

import { Accordion } from '@components/molecules/Accordion';
import { AccordionWithImage } from '@components/molecules/AccordionWithImage';
import { AccordionWithTable } from '@components/molecules/AccordionWithTable';
import { RichTextSection } from '@components/molecules/RichTextSection';
import { ImageSection } from '@components/molecules/ImageSection';
import { FullWidthImageHero } from '@components/molecules/FullWidthImageHero';
import { Intro } from '@components/molecules/Intro';
import { StandardContentBlock } from '@components/molecules/StandardContentBlock';
import { ProductDetailBlock } from '@components/molecules/ProductDetailBlock';

const ComponentList = {
  'sections.accordion': Accordion,
  'sections.accordion-with-image': AccordionWithImage,
  'sections.accordion-with-table': AccordionWithTable,
  'sections.image_item': ImageSection,
  'sections.hero-full-width-image': FullWidthImageHero,
  'sections.rich-text-section': RichTextSection,
  'sections.image': ImageSection,
  'sections.intro': Intro,
  'sections.standard-content-block' : StandardContentBlock,
  'sections.product-detail-block' : ProductDetailBlock
};

export const createComponent = ({ id, strapi_component, index, ...data }) => {
  const TagName = ComponentList[String(strapi_component)];
  if (typeof TagName === `undefined`) {
    return (
      <div>
        component <b>{strapi_component}</b> not found
      </div>
    );
  }

  return <TagName key={`dynamic-zone-component-${strapi_component}-${index}`} data={data} />;
};
