import React from "react";
import { StaticQuery, graphql } from "gatsby";

import { Box, VStack, Link, Button} from "@chakra-ui/react";

import { RichText } from "@components/atoms/RichText";

const d = new Date();
const copyright = `&copy; ` + d.getFullYear() + ` DICK’s Sporting Goods`;

export const Footer = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          strapiMenu(slug: { eq: "footer-navigation" }) {
            title
            slug
            items {
              order
              title
              target
              url
              id
            }
          }
        }
      `}
      render={(data) => {
        return (
          <Box
            as="footer"
            bg="dsg.neutral.500"
            color="dsg.neutral.100"
            pt="3.125rem"
            pb="3.125rem"
            borderTop="1px solid #CBCBCB"
            mt="2.5rem"
          >
            <Box maxW="7xl" px="8" mx="auto">
              <Button
                as="a"
                href="https://www.dickssportinggoods.com/f/new-adidas-sports-bras"
                variant="primary"
                size="base"
              >
                SHOP ONLINE AT DICK'S
              </Button>
              <RichText
                  isComponent={false}
                  markdown={copyright}
                  fontSize="body"
                  fontWeight="500"
                  display="block"
                  paddingTop="2rem"
                  color="dsg.neutral.200"
                  textAlign="center"
              />
              <Box as="nav" spacing="1.875rem">
                <VStack
                  as="ul"
                  w="100%"
                  h="100%"
                  justifyContent="space-evenly"
                  p="0 2rem"
                >
                  {data.strapiMenu.items.map((link) => (
                    <Box
                      as="li"
                      key={`${link.id}`}
                      style={{ listStyleType: `none` }}
                    >
                      <Link
                        as="a"
                        href={link.url}
                        display="flex"
                        position="relative"
                        color="dsg.neutral.200"
                        fontSize="body"
                        fontWeight="500"
                        padding="0.875rem 0 0"
                        textDecoration="underline"
                      >
                        {link.title}
                      </Link>
                    </Box>
                  ))}
                </VStack>
              </Box>
            </Box>
          </Box>
        );
      }}
    />
  );
};
