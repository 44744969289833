import React from 'react';
import { marked } from 'marked';
import { Box } from '@chakra-ui/react';

import { Section } from '@components/atoms/Section';

import { richTextStyles } from './styles';

const trustedLinks = [`betland.live`];

export const RichText = ({ markdown, isComponent = true, ...rest }) => {
  let processedBody = marked(markdown);

  // ToDo: Refactor this into extending the markdown syntax rather than a string replace.
  processedBody = processedBody.replace(
    /(<img.*src="(.*)\.mp4".*alt="(.*)">)/g,
    ($1, $2, $3) => `
    <div style="height: unset;">
      <video src="${process.env.URL}${$3}.mp4" preload="auto" controls="" style="width: 100%; height: 100%;"></video>
    </div>`
  );

  // ToDo: Refactor this into extending the markdown syntax rather than a string replace.
  processedBody = processedBody.replace(
    /<a href="(.*)">(.*)<\/a>/gm,
    ($1, $2, $3) => {
      if (
        ($2 && $2.indexOf(`/`) === 0) ||
        new RegExp(`${trustedLinks.join(`|`)}`).test($2)
      ) {
        return `<a href="${$2}">${$3}</a>`;
      }
      return `<a href="${$2}" target="_blank" rel="nofollow">${$3}</a>`;
    }
  );

  if (isComponent) {
    return (
      <Section>
        <Box
          dangerouslySetInnerHTML={{ __html: processedBody }}
          sx={richTextStyles}
          color="gray.600"
          {...rest}
        />
      </Section>
    );
  }

  return (
    <Box
      dangerouslySetInnerHTML={{ __html: processedBody }}
      sx={richTextStyles}
      color="gray.600"
      {...rest}
    />
  );
};
