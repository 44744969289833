/* eslint-disable react/no-danger */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
import Helmet from 'react-helmet';
export const CookieScript = () => (
  <Helmet>
    <script src="https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js"  type="text/javascript" charset="UTF-8" data-domain-script="4e279ba0-e250-4e02-831c-73824f493212" ></script>
    <script
      type="text/javascript"
      dangerouslySetInnerHTML={{ __html: `function OptanonWrapper() {}` }}
    />
  </Helmet>
);