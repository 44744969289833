import React, { useState } from 'react';
import ReactPlayer from 'react-player/vimeo';

export const Video = ({ relativeURL, playing, onEnded}) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handleOnEnded = () => {
    setTimeout(() => {
      onEnded()
    }, 1000)
  }

  return (
    <>
      <ReactPlayer
        url={relativeURL}
        playsinline={true}
        controls
        height="unset"
        width="100%"
        playing={playing}
        onPlay={() => { setIsPlaying(true); }}
        onPause={() => { setIsPlaying(false); }}
        onEnded={handleOnEnded}
      />
    </>
  );
};
