import React from 'react';
import { Box } from '@chakra-ui/react';

import { useSetMediaQueries, findSize } from '@helpers/mediaQueryHooks';

export const Section = ({
  backgroundComponents = null,
  isNarrow = false,
  vCentered = false,
  id,
  children,
  ...props
}) => {
  const mQuery = useSetMediaQueries();

  return (
    <Box
      paddingX={[`25px`, `50px`, `100px`]}
      paddingY={[`25px`, `50px`, `75px`]}
      as="section"
      position="relative"
      overflow="hidden"
      zIndex="1"
      id={id}
      display={vCentered ? `flex` : null}
      width={vCentered ? `100%` : null}
      {...props}>
      {backgroundComponents}
      <Box
        maxW={isNarrow ? `4xl` : `7xl`}
        mx="auto"
        display={vCentered ? `flex` : null}
        width={vCentered ? `100%` : null}
        alignItems={vCentered ? `center` : null}>
        {children}
      </Box>
    </Box>
  );
};
