import React from 'react';

import {
    Button,
    Heading,
  } from '@chakra-ui/react';

import { Link as GatsbyLink } from "gatsby"

import { Section } from '@components/atoms/Section';
import { RichText } from "@components/atoms/RichText";

export const Intro = ({ data }) => {
    const heading = data.heading.Text;
    const htag = data.heading.htag;
    const copy = data.text.body.data.body;
    const buttonUrl = data.button.url;
    const buttonLabel = data.button.label;

    return (
    <Section
        px={{ base: `1.563rem`, sml: `3.125rem !important` }}
        pt="2rem !important"
        pb="0 !important">
        <Heading
            key={heading}
            as={htag}
            variant="alpha"
            textAlign="center"
            margin="0 auto"
            maxW="20.5rem"
        >
          {heading}
        </Heading>
        <RichText
            isComponent={false}
            markdown={copy}
            fontSize="sm"
            display="block"
            paddingTop="1rem"
            color="black"
            textAlign="center"
        />
        <Button
            as={GatsbyLink}
            to={buttonUrl}
            state={buttonUrl}
            variant="primary"
            size="base"
            mt="1rem"
            >
            {buttonLabel}
        </Button>
    </Section>
  )
};
