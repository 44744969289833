import React from 'react';
import { Box } from '@chakra-ui/react';

import { Media } from '@components/atoms/Media';

import HeroBackground from '@assets/hero-background.svg';

export const FullWidthImageHero = ({ data }) => {
  return (
    <Box as="section" bg="dsg.brand.50" position="relative">
      <Box w="100%" h="100%" position="absolute" objectFit="cover" >
        <HeroBackground width="100%" height="100%"/>
      </Box>
        {data.hero_image && (
          <Box p="2rem">
            <Media media={data.hero_image} w="full" />
          </Box>
        )}
    </Box>
  )
};
