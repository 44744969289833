export const tableStyles = {
    'table, th, td': {
      border: `1px solid`
    },
    'th, td': {
      padding: `0.938rem 0`,
      textAlign: `center`,
      color: `#585453`,
      fontSize: `1rem`,
      fontWeight: `500`,
    },
    th: {
        background: `dsg.brand.100`,
        color: `dsg.neutral.50`,
        borderColor: `dsg.neutral.100`
    },
    table: {
      margin: `2rem 0`,
      borderColor: `dsg.neutral.100`
    }
  };
  