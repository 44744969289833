import { useEffect, useState } from 'react';
import { useMediaQuery } from '@chakra-ui/react';

export const useIsLargerThanMd = () => {
  const chakraMediaQuery = useMediaQuery(`(min-width: 48em)`);

  const [mediaQuery, setMediaQuery] = useState(false);

  useEffect(() => {
    if (chakraMediaQuery !== mediaQuery) {
      setMediaQuery(chakraMediaQuery);
    }
  }, [chakraMediaQuery]);

  return mediaQuery?.[0];
};
