import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { Box, Flex, HStack, Link, createIcon } from "@chakra-ui/react";
import { Link as GatsbyLink } from "gatsby"

import Logo from "@components/atoms/Logo/Logo";

const HomeIcon = createIcon({
  displayName: `ChevronLeft`,
  viewBox: `0 0 12 22`,
  path: <path d="M11 21L1 11L11 1" fill="none" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
});

export const Header = ({location}) => {
  return (
    <StaticQuery
      query={graphql`{
        strapiMenu(slug: {eq: "primary-navigation"}) {
          title
          slug
          items {
            order
            title
            target
            url
            id
          }
        }
      }`}
      render={(data) => {
        return (
          <Box as="header" overflow="hidden">
            <Box maxW="7xl"  mx="auto">
              <Box
                position="relative"
              >
              { location.pathname !== '/' &&
                <Link as={GatsbyLink} to="/"
                  position="absolute"
                  left="32px"
                  top="50%"
                  transform="translateY(-50%)"
                >
                  <HomeIcon />
                </Link>
              }
                <Flex as="span" alignItems="center" justifyContent="center" p="22px 0">
                  <Logo invert height="47px" />
                </Flex>
              </Box>
              {(location.pathname === '/' ||
                location.pathname.includes('/cups') ||
                location.pathname.includes('/band') ||
                location.pathname.includes('/straps') ||
                location.pathname.includes('/shop')
                ) &&
                <Box justify="space-between">
                  <HStack as="nav" spacing="20" bg="#98272F">
                    <HStack
                      display={{ base: `flex` }}
                      as="ul"
                      w="100%"
                      h="100%"
                      justifyContent="space-between"
                      p="0 2rem"
                    >
                      {data.strapiMenu.items.map((link) => (
                        <Box
                          as="li"
                          key={`${link.id}`}
                          style={{ listStyleType: `none` }}
                        >
                          <Link
                            as={GatsbyLink}
                            to={link.url}
                            display="flex"
                            position="relative"
                            color="dsg.neutral.50"
                            fontSize="gamma"
                            padding="0.875rem 0"
                            fontFamily="brand"
                            opacity={ location.pathname === link.url ? `100%` : `60%`}
                            textTransform="uppercase"
                            _hover={{
                              textDecoration: `none`,
                              opacity: `100%`
                            }}
                            _after={{
                              content: location.pathname === link.url ? `""` : `none`,
                              position: `absolute`,
                              bottom: `0`,
                              w: `100%`,
                              h: `4px`,
                              bg: `dsg.neutral.50`
                            }}
                          >
                            {link.title}
                          </Link>
                        </Box>
                      ))}
                    </HStack>
                  </HStack>
                </Box>
              }
            </Box>
          </Box>
        );
      }}
    />
  );
};
