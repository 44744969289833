import React from 'react';

import {
    Button,
    Heading,
    Box
  } from '@chakra-ui/react';

import { Section } from '@components/atoms/Section';
import { RichText } from "@components/atoms/RichText";
import { Media } from '@components/atoms/Media';

import { Link as GatsbyLink } from "gatsby"

export const StandardContentBlock = ({ data }) => {
    const { 
        image, 
        heading: {Text: heading, htag},
        text: {body: {data: {body: copy}}},
        button: { url: buttonUrl, label: buttonLabel },
        no_padding_top
     
     } = data;
     
    return (
    <Section
        px={{ base: `1.563rem`, sml: `2rem !important` }}
        pt={ no_padding_top ? `2rem! important` : `4rem !important`}
        pb="0 !important"
    >
        {image && (
            <Box>
                <Media media={image} w="full" />
            </Box>
        )}
        <Box px="1.125rem">
            {heading && (
                <Heading
                    key={heading}
                    as={htag}
                    variant="beta"
                    maxW="18.5rem"
                    mt="1.5rem"
                >
                {heading}
                </Heading>
            )}
            {copy && (
                <RichText
                    isComponent={false}
                    markdown={copy}
                    fontSize="body"
                    display="block"
                    paddingTop="1rem"
                    color="black"
                />
            )}
            {buttonUrl && buttonLabel && (
                <Button
                    as={GatsbyLink}
                    to={buttonUrl}
                    state={buttonUrl}
                    variant="primary"
                    size="base"
                    mt=".5rem"
                    >
                    {buttonLabel}
                </Button>
            )}
        </Box>
    </Section>
  )
};
