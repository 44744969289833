import React from 'react';

import {
    Button,
    Heading,
    Box,
    Modal,
    AspectRatio,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalCloseButton,
    useDisclosure
  } from '@chakra-ui/react';

import { Section } from '@components/atoms/Section';
import { RichText } from "@components/atoms/RichText";
import { Media } from '@components/atoms/Media';
import { Video } from '@components/atoms/Video';


import { Link as GatsbyLink } from "gatsby"

export const ProductDetailBlock = ({ data }) => {
    const { 
        image, 
        heading: {Text: heading, htag},
        copy: {body: {data: {body: copy}}},
        video_thumbnail,
        video_source
     
     } = data;
    const additionalCopy = data?.additional_copy?.body?.data?.body;
    const buttonUrl = data?.button?.url;
    const buttonLabel = data?.button?.label;

    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
    <Section
        px={{ base: `1.563rem`, sml: `2rem !important` }}
        pt="2rem !important"
        pb="0 !important"
    >
        {image && (
            <Box>
                <Media media={image} w="full" />
            </Box>
        )}
        <Box px="1.125rem">
            {heading && (
                <Heading
                    key={heading}
                    as={htag}
                    variant="beta"
                    maxW="18.5rem"
                    mt="1.5rem"
                >
                {heading}
                </Heading>
            )}
            {copy && (
                <RichText
                    isComponent={false}
                    markdown={copy}
                    fontSize="body"
                    display="block"
                    paddingTop="1rem"
                    color="black"
                />
            )}
            {video_thumbnail && (
            <Box>
                <Media media={video_thumbnail} w="full" my="0.5rem" onClick={onOpen}/>
            </Box>
            )}
            {additionalCopy && (
                <RichText
                    isComponent={false}
                    markdown={additionalCopy}
                    fontSize="body"
                    display="block"
                    paddingTop="1rem"
                    color="black"
                />
            )}
            {buttonUrl && buttonLabel && (
                <Button
                    as={GatsbyLink}
                    to={buttonUrl}
                    state={buttonUrl}
                    variant="primary"
                    size="base"
                    mt=".5rem"
                    >
                    {buttonLabel}
                </Button>
            )}
            <Modal isOpen={isOpen} onClose={onClose} isCentered position="relative">
               
                <ModalOverlay>
                    <ModalCloseButton onClick={onClose}
                        position="absolute"
                        top="1.563rem"
                        right="1.875rem"
                        color="dsg.neutral.50"
                        zIndex="2"
                        size="lg"
                        spacing="0"
                    >
                    </ModalCloseButton>
                </ModalOverlay>
                <ModalContent borderRadius="0">
                <ModalBody p="0">
                    <AspectRatio ratio={1/1}>
                        <Video
                            relativeURL={video_source}
                            playing="true"
                            onEnded={onClose}
                            >
                        </Video>
                    </AspectRatio>
                </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    </Section>
  )
};