import React from 'react';

import { Media } from '@components/atoms/Media';
import { Section } from '@components/atoms/Section';

export const ImageSection = ({ data }) => {
    return (
    <Section isNarrow>
      <Media media={data.image_section} w="full" />
    </Section>
  )
};
