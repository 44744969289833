/* eslint-disable react/no-danger */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
import Helmet from 'react-helmet';
export const SEO = () => (
  <Helmet>
    <title>DSG Bra Rev</title>
    <meta name="description" content="DSG Bra Rev - Find your perfect fit" />
    <meta name="author" content="Dick's Sporting Goods"/>
    <meta
        name="viewport"
        content="width=device-width,minimum-scale=1.0,initial-scale=1.0,maximum-scale=5.0,viewport-fit=cover"
    />
  </Helmet>
);