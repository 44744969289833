import { useEffect, useState } from 'react';
import { useMediaQuery } from '@chakra-ui/react';
import { useIsLargerThanMd } from './useIsLargerThanMd';

const useSetMediaQueries = () => {
  const chakraMediaQueryLg = useMediaQuery(`(min-width: 75em)`);
  const chakraMediaQueryMd = useMediaQuery(`(min-width: 48em)`);

  const [mediaQuery, setMediaQuery] = useState(false);
  const [mediaQueryResult, setMediaQueryResult] = useState(false);

  useEffect(() => {
    switch (true) {
      case chakraMediaQueryLg[0]:
        return setMediaQuery(`lg`);
      case chakraMediaQueryMd[0]:
        return setMediaQuery(`md`);
      default:
        return setMediaQuery(false);
    }
  }, [chakraMediaQueryLg, chakraMediaQueryMd]);

  useEffect(() => {
    if (mediaQuery !== mediaQueryResult) {
      setMediaQueryResult(mediaQuery);
    }
  }, [mediaQuery, mediaQueryResult]);

  return mediaQueryResult;
};

const findSize = ({ mQuery, sm, md, lg }) => {
  switch (mQuery) {
    case false:
      return sm;
    case `md`:
      return md;
    case `lg`:
      return lg;
    default:
      return null;
  }
};

export { useSetMediaQueries, useIsLargerThanMd, findSize };
